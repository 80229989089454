import { Box, Center, Link, Text } from "@chakra-ui/react";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { FormattedMessage } from "react-intl";
import { CenteredContent } from "../../CenteredContent";
import { Href } from "../../Href";
import { PostCard } from "../PostCard";
import { Slider } from "@raiden/library-ui/components/Slider";
import { getPostSlug } from "@raiden/library-ui/helpers/posts/getSlug";

/**
 * @typedef {object} Props
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>>} swrResponsePostsTypeArticle
 */
/**
 * @param {Props} props
 */
export function PostsSlider({ swrResponsePostsTypeArticle }) {
  const { locale, defaultLocale } = useLocale();

  return (
    <Box>
      <CenteredContent
        maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
        <Slider
          itemWidth={{
            base: "calc(100% - (2rem / 1))",
            sm: "calc(50% - (2rem / 2))",
            md: "calc(33.33% - (2rem / 3))",
            lg: "calc(25%)",
          }}>
          {swrResponsePostsTypeArticle.data?.data.map((post) => (
            <Href
              key={post.id}
              href={generateFrontPath({
                id: "@front.articles.view",
                parameters: {
                  postSlug: getPostSlug({ post, locale, defaultLocale }),
                },
              })}>
              <PostCard post={post} maxContainerWidth={415} />
            </Href>
          ))}
        </Slider>
      </CenteredContent>

      <Box px="1rem" mt="2rem">
        <CenteredContent>
          <Center>
            <Href
              href={generateFrontPath({
                id: "@front.news.search",
              })}>
              <Link color="brandPrimary.500" as="span">
                <Text
                  as="span"
                  fontSize="1rem"
                  lineHeight="1.5rem"
                  color="inherit">
                  <FormattedMessage defaultMessage="Découvrez tous nos guides et actualités Clévacances" />
                </Text>
              </Link>
            </Href>
          </Center>
        </CenteredContent>
      </Box>
    </Box>
  );
}
