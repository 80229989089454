import { Box, Text } from "@chakra-ui/react";
import { CenteredContent } from "../CenteredContent";

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {string} [description]
 * @property {string} [dataGtmId]
 * @property {import("react").ReactNode} children
 */

/**
 * @param {Props} props
 */
export function SectionPublic({ title, description, children, dataGtmId }) {
  return (
    <Box data-gtm={dataGtmId}>
      <Box px="1rem">
        <CenteredContent>
          <Text as="h2" variant="h2">
            {title}
          </Text>

          {description && (
            <Text as="h3" variant="h5" mt="0.75rem">
              {description}
            </Text>
          )}
        </CenteredContent>
      </Box>

      <Box mt="1.5rem">{children}</Box>
    </Box>
  );
}
