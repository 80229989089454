import { Box, HStack, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { CenteredContent } from "../../CenteredContent";
import { Icon } from "@raiden/library-ui/components/Icon";
import { Slider } from "@raiden/library-ui/components/Slider";

/**
 * @typedef {object} Props
 * @property {number} categoryId
 * @property {import("react").Dispatch<import("react").SetStateAction<number>>} setCategoryId
 * @property {import("@raiden/library-ui/types/CampaignCategory").CampaignCategory[]} campaignCategories
 */
/**
 * @param {Props} props
 */
export function CampaignsCategoriesPicker({
  categoryId,
  setCategoryId,
  campaignCategories,
}) {
  const translate = useTranslate();

  return (
    <Box>
      <CenteredContent
        maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
        <Slider scrollSnap={false}>
          {campaignCategories.map((category) => (
            <HStack
              as="button"
              flexShrink={0}
              spacing="0.375rem"
              p="0.75rem"
              overflow="hidden"
              borderRadius="full"
              borderWidth="1px"
              borderColor={categoryId === category.id ? "#000" : "transparent"}
              onClick={(e) => {
                setCategoryId(category.id);
                setTimeout(() => {
                  /** @type {HTMLDivElement} */ (e.target).scrollIntoView({
                    inline: "center",
                    block: "nearest",
                    behavior: "smooth",
                  });
                }, 0);
              }}
              key={category.id}>
              {category.icon && <Icon icon={category.icon} size="1rem" />}

              <Text variant="text-xs" noOfLines={1}>
                {translate(category.title)}
              </Text>
            </HStack>
          ))}
        </Slider>
      </CenteredContent>
    </Box>
  );
}
