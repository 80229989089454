import {
  AspectRatio,
  Box,
  DarkMode,
  HStack,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { FormattedDate } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Post").Post} post
 * @property {number} maxContainerWidth
 */
/**
 * @param {Props} props
 */
export function PostCard({ post, maxContainerWidth }) {
  const translate = useTranslate();

  return (
    <Stack
      spacing="0"
      position="relative"
      borderRadius="0.625rem"
      overflow="hidden"
      border="1px solid #eee"
      w="full"
      h="full">
      <AspectRatio ratio={16 / 9} backgroundColor="#ccc">
        <PampaImage
          image={post.current_revision?.cover}
          placeholderSrc={generateFrontPath({
            id: "@front.internal-assets",
            parameters: {
              filePath: "placeholder-article-16-9.svg",
            },
            includeBasePath: true,
          })}
          maxContainerWidth={maxContainerWidth}
          mode="crop"
          format="miniature"
          w="full"
          h="full"
          alt=""
        />
      </AspectRatio>

      {post.publication_date && (
        <DarkMode>
          <Box position="absolute" top="1rem" left="1rem">
            <Tag
              variant="solid"
              backgroundColor="brandPrimary.500"
              borderRadius="full"
              border="1px solid rgba(255, 255, 255, .5)">
              <FormattedDate
                value={post.publication_date}
                day="2-digit"
                month="long"
              />
            </Tag>
          </Box>
        </DarkMode>
      )}

      <Stack
        flexGrow={1}
        spacing="0"
        justify="flex-end"
        backgroundColor="#fff"
        py="1rem"
        px="1.5rem">
        {(post.categories?.length ?? 0) > 0 && (
          <HStack>
            {post.categories?.map((category) => (
              <Text
                as="span"
                color="brandPrimary.500"
                variant="text-xs"
                size="sm"
                colorScheme="gray"
                borderRadius="full"
                noOfLines={1}
                key={category.id}>
                {translate(category.name)}
              </Text>
            ))}
          </HStack>
        )}

        <Text fontSize="1rem" noOfLines={2}>
          {translate(post.current_revision?.title)}
        </Text>
      </Stack>
    </Stack>
  );
}
