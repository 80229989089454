import { Box, Spinner } from "@chakra-ui/react";
import { Slider } from "@raiden/library-ui/components/Slider";
import {
  CAMPAIGNS_SORT_VALUE_RANDOM,
  CAMPAIGNS_TYPE_VALUE_DESTINATION,
} from "@raiden/library-ui/constants/campaigns";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useEffect, useState } from "react";
import { CampaignsCategoriesPicker } from "../../CampaignsCategories/Picker";
import { CenteredContent } from "../../CenteredContent";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { Disable } from "../../Disable";
import { CampaignCard } from "../Card";

/**
 * @param {object} params
 * @param {number} params.categoryId
 * @param {number} [params.regionId]
 * @param {number} [params.departmentId]
 * @param {boolean} [params.isFederal]
 */
export function getUrlCampaignsTypeDestination({
  categoryId,
  regionId,
  departmentId,
  isFederal = false,
}) {
  return generateApiUrl({
    id: "@campaigns.viewAny",
    query: {
      type: CAMPAIGNS_TYPE_VALUE_DESTINATION,
      category_id: [categoryId],
      region_id: [regionId],
      department_id: [departmentId],
      sort: CAMPAIGNS_SORT_VALUE_RANDOM,
      ...(isFederal && { is_federal: true }),
      per_page: 16,
      fields: ["campaigns.cover", "campaigns.categories"],
    },
  });
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/CampaignCategory").CampaignCategory[]} campaignCategories
 * @property {number} [regionId]
 * @property {number} [departmentId]
 * @property {number} [initialCategoryId]
 * @property {boolean} [isFederal]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>} [campaignsTypeDestinationResponse]
 */
/**
 * @param {Props} props
 */
export function CampaignsShowcaseDestination({
  regionId,
  departmentId,
  isFederal,
  initialCategoryId,
  campaignCategories,
  campaignsTypeDestinationResponse,
}) {
  const [categoryId, setCategoryId] = useState(
    initialCategoryId ?? campaignCategories[0]?.id,
  );

  useEffect(() => {
    const categoryId = campaignCategories[0]?.id;
    if (categoryId !== undefined) {
      setCategoryId(categoryId);
    }
  }, [campaignCategories, setCategoryId]);

  useEffect(() => {
    if (
      categoryId === undefined &&
      campaignCategories &&
      campaignCategories.length > 0
    ) {
      setCategoryId(campaignCategories[0].id);
    }
  }, [campaignCategories, categoryId]);

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Campaign").Campaign[]>} */
  const { swrResponse: swrResponseCampaignsTypeDestination } = useApi(
    categoryId !== undefined
      ? getUrlCampaignsTypeDestination({
          regionId,
          departmentId,
          categoryId,
          isFederal,
        })
      : null,
    {
      swrConfig: {
        fallbackData: campaignsTypeDestinationResponse,
        keepPreviousData: true,
      },
    },
  );

  const [forceEnable, setForceEnable] = useState(true);

  useEffect(() => {
    setForceEnable(false);
  }, []);

  return (
    <Box>
      {campaignCategories && (
        <CampaignsCategoriesPicker
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          campaignCategories={campaignCategories}
        />
      )}

      <Box mt="1.5rem">
        <Disable
          isDisabled={
            swrResponseCampaignsTypeDestination.isValidating && !forceEnable
          }>
          <CenteredContent
            maxWidth={({ defaultMaxWidth }) =>
              `calc(${defaultMaxWidth} + 2rem)`
            }>
            <DataHandler
              swrResponse={swrResponseCampaignsTypeDestination}
              keepDataOnRevalidation={true}>
              {({ data: campaignsTypeDestinationData }) => (
                <Box position="relative">
                  <Slider
                    itemWidth={{
                      base: "calc(100% - (2rem / 1))",
                      sm: "calc(50% - (2rem / 2))",
                      md: "calc(33.33% - (2rem / 3))",
                      lg: "calc(25%)",
                    }}>
                    {campaignsTypeDestinationData.map((campaign) => (
                      <CampaignCard
                        campaign={campaign}
                        aspectRatio={1}
                        key={campaign.id}
                      />
                    ))}
                  </Slider>

                  {swrResponseCampaignsTypeDestination.isValidating &&
                    !forceEnable && (
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translateX(-50%) translateY(-50%)">
                        <Spinner size="lg" />
                      </Box>
                    )}
                </Box>
              )}
            </DataHandler>
          </CenteredContent>
        </Disable>
      </Box>
    </Box>
  );
}
