import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getSearchFormDefaultValues } from "../Search/Handler";
import encodeQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeQuery";

/**
 * @typedef {object} SearchHandlerFormValues
 * @property {import("../../Places/Search").PlacesSearchValue} location
 * @property {string} adults
 * @property {string} children
 * @property {string} babies
 * @property {string} pets
 */

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Region").Region} [region]
 * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
 * @property {boolean} [withFormTag]
 * @property {(params: {form: import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>, submit: () => void}) => import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function SearchWidgetHandler({
  region,
  department,
  withFormTag = true,
  children,
}) {
  const form = useForm({
    defaultValues: getSearchFormDefaultValues({ region, department }),
  });
  const { handleSubmit } = form;

  const router = useRouter();

  const submit = useCallback(
    (event) => {
      event?.preventDefault();
      handleSubmit((values) => {
        router.push(
          `${generateFrontPath({
            id: "@front.search",
          })}?${encodeQuery(values)}`,
        );
      })();
    },
    [handleSubmit, router],
  );

  return (
    <FormProvider {...form}>
      <ConditionalWrapper
        hasWrapper={withFormTag}
        wrapper={(children) => (
          <form id="search" onSubmit={submit}>
            {children}
          </form>
        )}>
        {children({ form, submit })}
      </ConditionalWrapper>
    </FormProvider>
  );
}
