import { AspectRatio, Box, DarkMode, Text } from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import {
  CAMPAIGNS_TYPE_VALUE_DESTINATION,
  CAMPAIGNS_TYPE_VALUE_THEMATIC,
} from "@raiden/library-ui/constants/campaigns";
import { getCampaignSlug } from "@raiden/library-ui/helpers/campaigns/getSlug";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useCallback, useMemo } from "react";
import NextLink from "next/link";
import useLocale from "@raiden/library-ui/hooks/useLocale";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Campaign").Campaign} campaign
 * @property {number} aspectRatio
 */
/**
 * @param {Props} props
 */
export function CampaignCard({ campaign, aspectRatio }) {
  const translate = useTranslate();

  const { locale, defaultLocale } = useLocale();

  const getPlaceholder = useCallback(() => {
    const randomBetween1And3 = Math.floor(Math.random() * 3) + 1;

    return aspectRatio === 1
      ? generateFrontPath({
          id: "@front.internal-assets",
          parameters: {
            filePath: `placeholder-campaign-${randomBetween1And3}-square.jpg`,
          },
          includeBasePath: true,
        })
      : generateFrontPath({
          id: "@front.internal-assets",
          parameters: {
            filePath: `placeholder-campaign-${randomBetween1And3}.jpg`,
          },
          includeBasePath: true,
        });
  }, [aspectRatio]);

  const href = useMemo(() => {
    switch (campaign.type) {
      case CAMPAIGNS_TYPE_VALUE_DESTINATION:
        return generateFrontPath({
          id: "@front.destinations.view",
          parameters: {
            campaignSlug: getCampaignSlug({ campaign, locale, defaultLocale }),
          },
        });
      case CAMPAIGNS_TYPE_VALUE_THEMATIC:
        return generateFrontPath({
          id: "@front.thematics.view",
          parameters: {
            campaignSlug: getCampaignSlug({ campaign, locale, defaultLocale }),
          },
        });
      default:
        return undefined;
    }
  }, [campaign, defaultLocale, locale]);

  return (
    <NextLink href={href ?? ""} passHref={true}>
      <Box
        as="a"
        position="relative"
        borderRadius="0.625rem"
        overflow="hidden"
        backgroundColor="#eee">
        <AspectRatio ratio={aspectRatio} overflow="hidden">
          <PampaImage
            image={campaign.cover}
            mode="crop"
            format={aspectRatio === 1 ? "square" : "miniature"}
            alt=""
            maxContainerWidth={aspectRatio === 1 ? 415 : 532}
            placeholderSrc={getPlaceholder()}
            w="full"
          />
        </AspectRatio>

        <DarkMode>
          <Box
            position="absolute"
            left="0"
            right="0"
            bottom="0"
            backgroundColor="rgba(14, 147, 212, 0.10)"
            backdropFilter="blur(6px)"
            px="1.5rem"
            py="0.75rem">
            <Text variant="button-md" noOfLines={1}>
              {translate(campaign.title)}
            </Text>
          </Box>
        </DarkMode>
      </Box>
    </NextLink>
  );
}
