import { Box, Flex, Grid } from "@chakra-ui/react";
import { Slider } from "@raiden/library-ui/components/Slider";
import { Fragment, useMemo } from "react";
import { CenteredContent } from "../../CenteredContent";
import { CampaignCard } from "../Card";

/**
 * @param {string} area
 * @returns {number}
 */
function getAspectRatio(area) {
  if (area === "b" || area === "d") {
    return 532 / 258;
  }
  return 1;
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Campaign").Campaign[]} campaigns
 */
/**
 * @param {Props} props
 */
export function CampaignsThematicShowcase({ campaigns }) {
  const groupedCampaigns = useMemo(() => {
    // group campaigns into batch of 6

    const groupedCampaigns = [];

    for (let i = 0; i < campaigns.length; i += 6) {
      groupedCampaigns.push(campaigns.slice(i, i + 6));
    }
    return groupedCampaigns;
  }, [campaigns]);

  return (
    <Box>
      <Box display={{ base: "none", lg: "block" }}>
        <CenteredContent
          maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
          <Slider itemWidth="100%">
            <Grid
              w="full"
              alignItems="stretch"
              gridTemplate={`
                "a b b c"
                "d d e f"
              `}
              gridGap="1rem">
              {groupedCampaigns.map((group, index) => (
                <Fragment key={index}>
                  {["a", "b", "c", "d", "e", "f"].map((area, areaIndex) => {
                    const campaign = group[areaIndex];
                    if (!campaign) {
                      return null;
                    }
                    return (
                      <Flex
                        alignItems="stretch"
                        gridArea={area}
                        key={campaign?.id}
                        sx={{
                          "& > *": {
                            flexGrow: 1,
                          },
                        }}>
                        <CampaignCard
                          campaign={campaign}
                          aspectRatio={getAspectRatio(area)}
                          key={campaign.id}
                        />
                      </Flex>
                    );
                  })}
                </Fragment>
              ))}
            </Grid>
          </Slider>
        </CenteredContent>
      </Box>

      <Box display={{ base: "block", lg: "none" }}>
        <CenteredContent
          maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
          <Slider
            itemWidth={{
              base: "calc(100% - (2rem / 1))",
              sm: "calc(50% - (2rem / 2))",
              md: "calc(33.33% - (2rem / 3))",
              lg: "calc(25%)",
            }}>
            {campaigns.map((campaign) => (
              <CampaignCard
                campaign={campaign}
                aspectRatio={1}
                key={campaign.id}
              />
            ))}
          </Slider>
        </CenteredContent>
      </Box>
    </Box>
  );
}
